
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























.faq-item {
  background-color: $c-white;
  border-top: 1px solid $c-gray-light;
  border-bottom: 1px solid $c-gray-light;

  & + & {
    margin-top: -1px;
  }
}

.faq-item__header {
  @extend %fw-bold;
  @extend %ff-alt;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing * 0.75 $spacing * 1.3;
  color: $c-gray-darkest;
  user-select: none;
  font-size: 1.8rem;
  line-height: 1.4em;
  cursor: pointer;
}

.faq-item__header__title {
  margin: 0;
  font-family: $ff-default;
  font-size: 1.8rem;
  font-weight: 400;
  transition: color 0.2s ease-out;

  .is-open & {
    @extend %fw-bold;
  }

  .faq-item__header:hover & {
    color: $c-mustard;
  }
}

.faq-item__header__icon {
  flex-shrink: 0;
  overflow: visible;
  width: 25px;
  height: 25px;
  padding: math.div($spacing, 4);
  margin-left: $spacing;
  fill: $c-gray-darkest;
  transition: 0.2s ease-out;
  transition-property: fill, transform;

  .faq-item__header:hover & {
    fill: $c-mustard;
  }

  .faq-item.is-open & {
    transform: rotate(180deg);
  }
}

[class*='faq-item-inner--'],
.faq-item-inner {
  overflow: hidden;

  &[class*='--grid'] {
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $spacing;
  }
}

.faq-item__group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 $spacing * 1.3 math.div($spacing, 2);

  &:last-child {
    margin-bottom: $spacing;
  }

  [class*='faq-item-inner--'][class*='--grid'] & {
    grid-column: 1 / 2;
  }

  [class*='faq-item-inner--'][class*='--grid'] &:nth-child(2) {
    margin-top: $spacing * 1.5;
  }

  [class*='faq-item-inner--'][class*='--grid'] &:last-child {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
  }

  ::v-deep {
    ol {
      margin: $spacing 0 $spacing * 2;
    }

    li {
      padding-left: $spacing * 3;
    }
  }
}

.faq-item__group__title {
  @extend %text-uppercase;

  margin-bottom: math.div($spacing, 2);
  color: $c-mustard;
  font-size: 1.2rem;
  line-height: 1.2em;
}

.faq-item__group__item {
  position: relative;
  overflow: hidden;
  padding: math.div($spacing, 4) 0;
  color: $c-gray-darkest;
  font-size: 1.4rem;
  line-height: 1.2em;
  cursor: pointer;
  user-select: none;

  & + & {
    margin-top: math.div($spacing, 4);
  }

  &:hover {
    text-decoration: underline;
  }

  &.is-active {
    color: $c-dev-error;
    text-decoration: underline;
    cursor: default;
    pointer-events: none;
    user-select: none;
  }
}
