
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































.flexible-faq__title {
  margin-bottom: var(--spacing-l);
}

.flexible-faq__subtitle {
  margin-bottom: var(--spacing-xs);
}
